<template>
  <div class="animated fadeIn">
    <!-- Basic -->
    <b-card-header>
      History Transaction
      <div class="card-header-actions">
        <a class="card-header-action" href="voucher-manage-balance" rel="noreferrer noopener">
          <small class="text-muted">Back</small>
        </a>
      </div>
    </b-card-header>
    <b-card>
      <div class="loading" v-if="isLoading == true">
        <div class="sk-three-bounce">
          <div class="sk-child sk-bounce1"></div>
          <div class="sk-child sk-bounce2"></div>
          <div class="sk-child sk-bounce3"></div>
        </div>
      </div>
      <b-row class="mb-3">
        <b-col sm="6">
          <div>
            <b-form-group
              class="mb-3"
              label="Transaction Date"
              label-for="daterange"
            >
              <b-input-group
              >
                <b-input-group-prepend is-text>
                  <i class="fa fa-calendar"></i>
                </b-input-group-prepend>
                <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
                <b-input-group-append>
                  <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col sm="6" v-if="$can('partner_voucher_manage_balance_export_history')">
          <div class="wrapper-export">
            <button class="btn btn-success" type="button" @click="exportTable">Export</button>
          </div>
        </b-col>
      </b-row>
      <vuetable ref="vuetable"
        :api-mode="false"
        :fields="fields"
        :per-page="limit"
        :data-manager="dataManager">
        <template slot="transaction_time-slot" slot-scope="prop">
          <span>{{ prop.rowData.transaction_time || '-' }}</span>
        </template>
        <template slot="transaction_type-slot" slot-scope="prop">
          <span>{{ prop.rowData.transaction_type || '-' }}</span>
        </template>
        <template slot="partner-slot" slot-scope="prop">
          <span>{{ prop.rowData.partner_name || '-' }}</span>
        </template>
        <template slot="amount-slot" slot-scope="prop">
          <span>{{ prop.rowData.amount | currency }}</span>
        </template>
        <template slot="transaction_status-slot" slot-scope="prop">
          <span>{{ prop.rowData.status || '-' }}</span>
        </template>
      </vuetable>
      <div class="vuetable-pagination ui basic segment grid">
        <div class="wrapper-pagination-custom">
          <div class="pagination-custom-info">
            <p>{{ this.paginationInfo }}</p>
          </div>
          <div class="pagination-custom-button">
            <button :disabled="currentPage === 1" @click="doMovePage('first')">
              <i v-if="currentPage === 1" class="fa fa-angle-double-left disabled"></i>
              <i v-else class="fa fa-angle-double-left"></i>
            </button>
            <button :disabled="currentPage === 1" @click="doMovePage('prev')">
              <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
              <i v-else class="fa fa-angle-left"></i>
            </button>
            <div>{{ this.currentPage }}</div>
            <button :disabled="data.length < 10" @click="doMovePage('next')">
              <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
              <i v-else class="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import _ from 'lodash'
  import axios from 'axios'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import moment from 'moment'
  
  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable)

  export default {
    components: {
      Vuetable
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        page: 1,
        currentPage: 1,
        limit: 10,
        data: [],
        isLoading: false,
        isLoadingTable: false,
        startDate: '',
        endDate: '',
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `voucher/partner/manage-balance/history`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sort: 'time|desc',
        sortOrder: [{
          direction: 'desc',
          field: 'transaction_time-slot',
          sortField: 'time'
        }],
        moreParams: {},
        fields: [
          {
            name: 'transaction_time-slot',
            sortField: 'time',
            title: 'Transaction Time'
          },
          {
            name: 'transaction_type-slot',
            title: 'Transaction Type'
          },
          {
            name: 'partner-slot',
            sortField: 'partner',
            title: 'Partners'
          },
          {
            name: 'amount-slot',
            title: 'Amount'
          },
          {
            name: 'transaction_status-slot',
            title: 'Transaction Status'
          },
        ]
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
      }
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      this.fetchs()
    },
    methods: {
      fetchs() {
        const newParams = this.stringifyParams(this.moreParams)

        this.$http.get(`voucher/partner/manage-balance/history?sort=${encodeURIComponent(this.sort)}&page=${this.page}&per_page=10${newParams}`)
          .then((response) => {
            const startIndex = (response.data.current_page - 1) * this.limit;
            const endIndex = startIndex + this.limit;

            this.data = response.data.data;
            this.currentPage = response.data.current_page;
            this.paginationInfo = response.data.data.length > 0 ? `Displaying ${startIndex + 1} to ${endIndex}` : 'No relevant data';

            if (this.isLoadingTable) this.isLoadingTable = false;
          })
          .catch((err) => {
            this.handleLoadError(err)
          })
      },
      exportTable() {
        const currentDate = moment(this.$setDate);
        this.isLoading = true

        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = currentDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`partner-voucher-balance/${startDate}/${endDate}?sort=${this.sortOrder[0].sortField}&order=${this.sortOrder[0].direction}`)
          .then((result) => {
            this.isLoading = false
            const exportPath = result.data.meta.data;
            window.location.href = exportPath;
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        this.fetchs()
      },
      onDateFilterReset() {
        this.moreParams = {}
        this.fetchs()
      },
      resetDateFilter() {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      doMovePage(value) {
        this.$events.$emit('movePage', value)
      },
      onMovePage (eventData) {
        if (eventData === 'first') {
          this.page = 1;
          this.fetchs();
        } else if (eventData === 'prev') {
          this.page--;
          this.fetchs();
        } else {
          this.page++;
          this.fetchs();
        }
      },
      dataManager(sortOrder, pagination) {
        let local = this.data;

        if (sortOrder.length > 0) {
          console.log('sort', this.sortOrder)
          const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;
          
          if (this.sort !== newSort) {
            this.sort = newSort;
            this.sortOrder = sortOrder;
            this.fetchs();
          }
          console.log(typeof sortOrder, sortOrder)
        }

        pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.limit
        );

        let from = pagination.from - 1;
        let to = from + this.limit;

        return {
          pagination: pagination,
          data: local.length > 0 ? _.slice(local, from, to) : []
        };
      },
      stringifyParams(params) {
        const filteredKeys = Object.keys(params).filter((key) => {
          const value = params[key]
          return value !== '' && value !== null && value !== undefined
        })

        if (filteredKeys.length === 0) {
          return ''
        } else if (filteredKeys.length === 1) {
          const key = filteredKeys[0];
          return `&${key}=${params[key]}`
        } else {
          return `&${filteredKeys.map((key) => `${key}=${params[key]}`).join('&')}`
        }
      },
    },
    mounted() {
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
      this.$events.$on('movePage', eventData => this.onMovePage(eventData))
    }
  }
</script>

<style>
  .wrapper-export {
    height: 100%;
    padding-bottom: 14px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  /* Absolute Center Spinner */
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }

  /* Table */
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  /* Pagination Custom */
  .wrapper-pagination-custom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9fafb;
  }

  .pagination-custom-button {
    display: flex;
  }

  .pagination-custom-button button {
    width: 28px;
    height: 47px;
    padding: 13px 16px;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: solid rgba(0,0,0,0.3);
  }

  .pagination-custom-button button:first-child {
    padding: 13px 16px 13px 30px;
    border-radius: 4px 0 0 4px;
    border-width: 1px 0 1px 1px
  }

  .pagination-custom-button button:nth-child(2),
  .pagination-custom-button button:last-child {
    font-size: 1.3em;
    border-width: 1px;
  }

  .pagination-custom-button button:last-child {
    border-radius: 0 4px 4px 0;
  }

  .pagination-custom-button button i.disabled {
    opacity: 0.6;
  }

  .pagination-custom-button div {
    width: 28px;
    height: 47px;
    font-size: 1em;
    font-weight: 500;
    background: #FFFFFF;
    border: solid rgba(0,0,0,0.3);
    border-width: 1px 0 1px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
